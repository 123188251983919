import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as _ from "lodash";
import { jsonData } from "./data";

function SuccessStoriesReadMore() {
  const { type } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    setData(jsonData[type]);
  }, [type]);

  return (
    <>
      <div className="container" style={{ paddingTop: "90px" }}>
        <div className="row" style={{ textAlign: "left" }}>
          <h1 className="success-title mobile-font-size-heading">{data?.title}</h1>
        </div>
      </div>

      <div className="container subtitle">
        <h2 className="u-fz-20" style={{textAlign:'left', marginBottom:'40px'}}>{data?.subTitle}</h2>

        <a href="/#contact" title="" className="btn btn-ep-primary-alt g-elm  ">
          GET IN TOUCH
        </a>

        {/* <a href="#" title="" className="btn btn-ep-secondary  ">
          Download Brochure{" "}
        </a> */}
      </div>

      <div className="container container-success-detail">
        <div
          className="row js-detail detail u-pt-50 u-pb-50"
          style={{ display: "block" }}
        >
          <div className="d-lg-flex">
            <div className="col-lg-6" id="standard">
              <p>{data?.description}</p>
              {data?.sections?.map((item, index) => {
                return (
                  <div>
                    <h3>{item.title}</h3>
                    <ul>
                      {item?.keys?.map((item, index) => {
                        return (
                          <li className="bullet--secondary-1 p-0" style={{ marginTop:'10px'}}>{(item)}</li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <div className="container u-pt-60 text-center centered d-flex justify-content-center flex-column">
                <div className="col-lg-12 u-pb-30 text-center">
                  <img className="img-responsive" src={data?.image} alt="" style={{height:'100%', width:'100%'}} />
                </div>
                <div className="col-lg-12 text-center justify-content-center">
                  <p className="col-lg-10 u-fz-20 u-pb-30 u-mb-0 m-auto">
                    <b>{data?.title}</b>
                  </p>
                  {/* <div className="col-sm-5 m-auto bot-gray-line"></div>
                  <div className="col-sm-12 u-pt-30 m-auto">
                    <div className="col-sm-12">
                      <strong>Tracey Gregory</strong>
                    </div>
                    <div className="col-sm-12 u-pb-30">
                      CFO and VP of Finance, Insul-Serve Management Ltd
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}

export default SuccessStoriesReadMore;
