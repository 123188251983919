import IMG1 from '../../assets/erps.webp'
import { BsChevronRight, BsDatabaseGear,BsWindows, BsFillDiagram3Fill,BsCartFill, BsPersonWorkspace } from 'react-icons/bs';
import { FaThumbsUp , FaHandshakeSimple, FaNewspaper,FaDollarSign} from 'react-icons/fa';
import { IoMdSettings } from 'react-icons/io';
import { GrIntegration } from 'react-icons/gr';
import { LuDatabaseBackup } from 'react-icons/lu';
import { FcFlowChart } from 'react-icons/fc';
import { RiDatabaseFill, RiTeamFill } from 'react-icons/ri';
import { HiUserGroup } from 'react-icons/hi';
import { GiAutoRepair, GiRoundTable ,GiStairsGoal, GiProgression } from 'react-icons/gi';
import { TbAntennaBars5  } from 'react-icons/tb';
import { MdSmartScreen ,MdManageAccounts, MdOutlineHandshake} from 'react-icons/md';
import { GrConnect } from 'react-icons/gr';


import IMG2 from '../../assets/team-1.jpg'
 
function ErpServices() {
  return (
    <div className='container mt-5 pt-5'>
    <h2 className="text-center mb-4 section-header mt-5">Enterprise Resource Planning
    </h2>
    <div className='row mt-5'>
      <div className='col-12 col-md-12 '>
        <img src={IMG1} height='50%' width='100%'/>
      </div>
      </div>
      {/* <div className='row' style={{marginTop:'50px'}}>
          <div className='col-12 card oracle-card p-4'>
              <h1 style={{textAlign:'center', color:'white', marginBottom:'30px'}}>ERP Services</h1>
              <div className='d-flex justify-content-start align-items-start'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Oracle Based Access ebs 5.0</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Web-Enabled Softwares</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Easy Installation</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Consistency and Reliability</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Multi Tier Security</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Data Consolidation</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Streamlined Process</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Seamless Integeration</p>
              </div>

          </div>
          </div>

          <div className='row' style={{marginTop:'50px'}}>
          <div className='col-12 card oracle-card p-4'>
              <h1 style={{textAlign:'center', color:'white', marginBottom:'30px'}}>Module Offered</h1>
              <div className='d-flex justify-content-start align-items-start'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Accounting and Finance Module</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Purchase Module</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Warehouse Module</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Sales Module</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>PPC Module</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Human Resource Module</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Customer Relationship Menagement</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Business Intelligence</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Machine Maintenance</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Multi Company Accounting</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Multi Site Management</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Project Management</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Increase Mobility and Stay Up to Date</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Improve collaboration and Communication</p>
              </div>
              <div className='d-flex justify-content-start align-items-center mt-3'>
                <BsChevronRight color='white' size={18}/>
                <p className='oracle-card-list'>Focus on Growth</p>
              </div>

          </div>
          </div> */}
<h2 className="text-center mb-4 section-header mt-5"><br></br> Our USP
    </h2>
          <div className='row mt-5 pt-5 gy-5 gy-md-0' style={{rowGap:'70px'}}>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
    <BsDatabaseGear style={{fontSize:'40px'}}></BsDatabaseGear>

    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Oracle Based Access EBS Capital
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
    <FcFlowChart style={{fontSize:'40px'}}></FcFlowChart>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Web-Enabled Softwares
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<IoMdSettings style={{fontSize:'40px'}}></IoMdSettings>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Easy Installation
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<FaThumbsUp style={{fontSize:'40px'}}></FaThumbsUp>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Consistency and Reliability
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<BsFillDiagram3Fill style={{fontSize:'40px'}}></BsFillDiagram3Fill>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Multi Tier Security
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<LuDatabaseBackup style={{fontSize:'40px'}}></LuDatabaseBackup>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Data Consolidation
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<GrIntegration style={{fontSize:'40px'}}></GrIntegration>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Streamlined Process
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<BsWindows style={{fontSize:'40px'}}></BsWindows>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Seamless Integeration
  </h4>

</div>
</div>

</div>

<h2 className="text-center mb-4 section-header mt-5">Modules Offered
    </h2>

    <div className='row mt-5 pt-5 gy-5 gy-md-0 erp-module-container' style={{rowGap:'70px'}}>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<FaDollarSign style={{fontSize:'40px'}}></FaDollarSign>

    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Accounting and Finance Module
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<BsCartFill style={{fontSize:'40px'}}></BsCartFill>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
Purchase Module
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<RiDatabaseFill style={{fontSize:'40px'}}></RiDatabaseFill>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Warehouse Module
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<MdOutlineHandshake style={{fontSize:'40px'}}></MdOutlineHandshake>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Sales Module
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<BsPersonWorkspace style={{fontSize:'40px'}}></BsPersonWorkspace>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  PPC Module
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<RiTeamFill style={{fontSize:'40px'}}></RiTeamFill>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Human Resource Module
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<HiUserGroup style={{fontSize:'40px'}}></HiUserGroup>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Customer Relationship Menagement
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<FaNewspaper style={{fontSize:'40px'}}></FaNewspaper>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Business Intelligence
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<GiAutoRepair style={{fontSize:'40px'}}></GiAutoRepair>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Machine Maintenance
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<TbAntennaBars5 style={{fontSize:'40px'}}></TbAntennaBars5>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Multi Company Accounting
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<MdManageAccounts style={{fontSize:'40px'}}></MdManageAccounts>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Multi Site Management
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<GiRoundTable style={{fontSize:'40px'}}></GiRoundTable>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Project Management
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<MdSmartScreen style={{fontSize:'40px'}}></MdSmartScreen>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Increase Mobility & Stay Up to Date
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<GiStairsGoal style={{fontSize:'40px'}}></GiStairsGoal>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Improve Collabration & Communication
  </h4>

</div>
</div>

<div className='col-12 col-md-3 '>
<div className='service-card position-relative card pt-5 pb-4 px-0'>
  <div className='position-absolute avatar-circle-container'>
    <div className='avatar-circle'>
    {/* <img src={IMG2}/> */}
<GiProgression style={{fontSize:'40px'}}></GiProgression>
    </div>
  </div>

  <h4 className='service-card-title px-3'>
  Focus on Growth
  </h4>

</div>
</div>


</div>




          <div className='row' style={{marginTop:'50px'}}>
          <div className='col-12 card-two oracle-card-two p-4 why-choose-us'>
              <h1 style={{textAlign:'center', color: 'rgb(3, 42, 88)', marginBottom:'30px'}}>Why Choose us</h1>
              <div className='d-flex align-items-start'>
                {/* <BsChevronRight color='white' size={18}/> */}
                <p className='oracle-card-list-two'><b>Robust Technology</b><br></br>It is powered by JAVA & ORACLE technologies that means you get best and easy to use of technology to run your business operations.</p>
              </div>
              <br></br>
              <div className='d-flex align-items-start'>
                {/* <BsChevronRight color='white' size={18}/> */}
                <p className='oracle-card-list-two'><b>Multi Company & Multi Locations</b><br></br>Can help you consolidate your businesses across multiple locations, thus making it economical for your business. </p>
              </div>
              <br></br>
              <div className='d-flex align-items-start'>
                {/* <BsChevronRight color='white' size={18}/> */}
                <p className='oracle-card-list-two'><b>Automated Workflows</b><br></br>It has automated workflows which can be customized according to users business needs.</p>
              </div>
              <br></br>
              <div className='d-flex align-items-start'>
                {/* <BsChevronRight color='white' size={18}/> */}
                <p className='oracle-card-list-two'> <b>OS Platform</b><br></br>It can be implemented on different OS Platform.</p>
              </div>
              <br></br>

          </div>
          </div>
      </div>
  );
}

export default ErpServices;
