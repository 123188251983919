import { BsChevronRight } from 'react-icons/bs';

import Customised from '../../assets/cad.jpg';

function CustomisedApplicationDev() {
  return (
    <div className='container mt-5 pt-5'>
      <h2 className="text-center mb-4 section-header mt-5">Customised Application Development</h2>
      <div className='row mt-5'>
        <div className='col-12 col-md-12 '>
          <img src={Customised} height='50%' width='100%'/>
        </div>
        </div>
        <div className='row' style={{marginTop:'50px'}}>
            <div className='col-12 card oracle-card p-4'>
                <h1 style={{textAlign:'center', color:'white'}}>Our Expertise</h1>
                <br></br>
                <br></br>
                <div className='d-flex justify-content-start align-items-start'>
                  {/* <BsChevronRight color='white' size={18}/> */}
                  <p className='oracle-card-list'>We develop customized applications software for various industries verticals such as Textile Footwear, Chemical, telecommunications, logistics by utilizing some of the most popular technologies.</p>
                </div>
                <br></br>
                <div className='d-flex justify-content-start align-items-center mt-3'>
                  {/* <BsChevronRight color='white' size={18}/> */}
                  <p className='oracle-card-list'>Our Certified IT Professionals meet all your business needs by providing flexible IT solutions. We understand that every business is different; from business goals to its processes, users and clients.</p>
                </div>
                <br></br>
                <div className='d-flex justify-content-start align-items-center mt-3'>
                  {/* <BsChevronRight color='white' size={18}/> */}
                  <p className='oracle-card-list'>Our designers create interfaces and designs that are user-friendly and simple to use. Employing the latest technologies and coding practices is what our programmers do best. By doing so, it encourages a shared ownership of the product among business analysts, software architects, designer, programmers, and testers; this shared ownership reduces the risk of miscommunication and divergence.</p>
                </div>
                <br></br>
                <div className='d-flex justify-content-start align-items-center mt-3'>
                  {/* <BsChevronRight color='white' size={18}/> */}
                  <p className='oracle-card-list'>Our goal of ensuring scalability for your future needs as well as keeping the software relevant and up to date It also enables continuing refinement and integration to avoid any unpleasant surprises just before the delivery date.
                  </p>
                 <br></br>
                </div>
  
            </div>
            </div>
        </div>
  );
}

export default CustomisedApplicationDev;
