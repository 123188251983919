import { Children, createContext, useState, useRef } from "react";

export const ObserverContext = createContext({});

export const ObserverProvider = ({ children }) => {
  // const serviceRef = useRef();
  // const homeRef = useRef();
  // section = default value = home
  // const sliderRef = useRef();
  // const learnMoreRef = useRef();
  // const startCounterRef = useRef();
  // const teamRef = useRef();
  // const successStoriesRef = useRef();
  // const startPortfolioRef = useRef();
  // const startQuoteRef = useRef();
  // const verticalsRef = useRef();
  // const methodologyRef = useRef();
  // const contactRef = useRef();
  const [activeSection, setActiveSection] = useState('home');
  return (
    <ObserverContext.Provider
      value={
      //   {
      //   serviceRef,
      //   homeRef,
      //   sliderRef,
      //   learnMoreRef,
      //   startCounterRef,
      //   teamRef,
      //   successStoriesRef,
      //   startPortfolioRef,
      //   startQuoteRef,
      //   verticalsRef,
      //   methodologyRef,
      //   contactRef,
      // }
     {activeSection,
      setActiveSection} 
    }
    >
      {children}
    </ObserverContext.Provider>
  );
};
