// import { useState } from 'react';
import { useContext, useEffect, useRef } from 'react';
import airVetrans from '../../assets/airVetransPic.png';
import ims from '../../assets/imsPic.png';
import erp from '../../assets/phonein hand.avif';
import erpPharma from '../../assets/erpPharmaPic.png';
import airforce from '../../assets/vetran.avif';
import jk from '../../assets/jk latest.jpg';


import { ObserverContext } from '../../Contexts/ObserverContext';

function Startportfolio() {
   const ref = useRef();

   const { activeSection, setActiveSection } = useContext(ObserverContext);

   useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          // set value in context for section = team.
          setActiveSection('work');
          console.log('this is updated section',activeSection)
        }
      });
      if (ref && ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        observer.disconnect();
      };
    }, [ref]);

  return (
    // <p>This is Startportfolio Page</p>
    <section className="blog-section" id="work" ref={ref}>
   {/* <div className="container" style={{marginBottom:'50px'}}>
      <h2 className="wow fadeInDown" data-wow-delay="300ms" style={{visibility: "hidden", animationDelay: "300ms", animationName: "none",display:'flex', justifyContent:'center'}}>Recent Projects</h2>
      <p className="wow fadeInDown" data-wow-delay="300ms" style={{visibility: "hidden", animationDelay: "300ms", animationName: "none",display:'flex', justifyContent:'center'}}>Intelligent and Deligent team provide smart solutions </p>
   </div> */}
   <div className="container">
      <div className="row">
         
         
         <div className="d-none d-lg-block col-lg-6 col-md-12 col-sm-12 col-xs-12 left-text left-heading wow fadeInLeftBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            <h2>Computerisation of Pension for Air Veterans</h2>
            {/* <h4>Client Name<br></br> <br></br><b>Indian Air Force - Directorate of Air Veterans</b></h4> */}
            <a className="btn button btn-rounded btn-light-blue btn-hvr-light-blue" href="/read-more/airVetrans">
               Read Full Story
               <div className="btn-hvr-setting">
                  <ul className="btn-hvr-setting-inner">
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                  </ul>
               </div>
            </a>
         </div>
         <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 wow fadeInRightBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            <img src={airforce} alt="image"/>
         </div>
         <div className="d-lg-none col-lg-6 col-md-12 col-sm-12 col-xs-12 left-text left-heading wow fadeInLeftBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            <h2 className='pt-5'>Computerisation of Pension for Air Veterans</h2>
            {/* <h4>Client Name<br></br> <br></br><b>Indian Air Force - Directorate of Air Veterans</b></h4> */}
            <a className="btn button btn-rounded btn-light-blue btn-hvr-light-blue" href="/read-more/airVetrans">
               Read Full Story
               <div className="btn-hvr-setting">
                  <ul className="btn-hvr-setting-inner">
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                  </ul>
               </div>
            </a>
         </div>

         <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 right-text wow fadeInLeftBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            <img src={jk} alt="image"/>
         </div>
         <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 right-text right-heading  wow fadeInRightBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            <h2>Inventory Management System IMS</h2> 
            {/* <h4>Client Name <br></br><br></br><b>Police Headquarters Jammu & Kashmir, Jammu</b></h4> */}
            <a className="btn button btn-rounded btn-light-blue btn-hvr-light-blue" href="/read-more/ims">
               Read Full Story
               <div className="btn-hvr-setting">
                  <ul className="btn-hvr-setting-inner">
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                  </ul>
               </div>
            </a>
         </div>


         <div className="d-none d-lg-block col-lg-6 col-md-12 col-sm-12 col-xs-12 left-text left-heading wow fadeInLeftBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none' ,paddingTop:'80px'}}>
            <h2>ERP Solution on Mobile</h2>
            {/* <h4>Client Name <br></br><br></br><b>Access Infotech Pvt. Ltds</b></h4> */}
            <a className="btn button btn-rounded btn-light-blue btn-hvr-light-blue" href="/read-more/erp">
               Read Full Story
               <div className="btn-hvr-setting">
                  <ul className="btn-hvr-setting-inner">
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                  </ul>
               </div>
            </a>
         </div>
         <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 wow fadeInRightBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none',paddingTop:'80px'}}>
            <img src={erp} alt="image"/>
         </div>
         <div className="d-lg-none col-lg-6 col-md-12 col-sm-12 col-xs-12 left-text left-heading wow fadeInLeftBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            <h2 className='pt-5'>ERP Solution on Mobile</h2>
            {/* <h4>Client Name <br></br><br></br><b>Access Infotech Pvt. Ltds</b></h4> */}
            <a className="btn button btn-rounded btn-light-blue btn-hvr-light-blue" href="/read-more/erp">
               Read Full Story
               <div className="btn-hvr-setting">
                  <ul className="btn-hvr-setting-inner">
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                  </ul>
               </div>
            </a>
         </div>

         <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pt-sm-4 pt-xl-5 right-text wow fadeInLeftBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            <img src={erpPharma} alt="image"/>
         </div>
         <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pt-xl-5 right-text right-heading  wow fadeInRightBig" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            <h2 className='mt-sm-1'>ERP Solution for Pharmaceutical Company</h2> 
            {/* <h4>Client Name <br></br><br></br><b>Cipla Pvt. Ltd</b></h4> */}
            <a className="btn button btn-rounded btn-light-blue btn-hvr-light-blue" href="/read-more/erpPharma">
               Read Full Story
               <div className="btn-hvr-setting">
                  <ul className="btn-hvr-setting-inner">
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                     <li className="btn-hvr-effect"></li>
                  </ul>
               </div>
            </a>
         </div>
      </div>
   </div>
</section>
  );
}

export default Startportfolio;