// import { useState } from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import slide3 from '../../assets/slide-3.jpg';
// import ss2 from '../../assets/ss2.png';
// import ss4 from '../../assets/ss4.png';
import risl from '../../assets/risl.jpg';
import interwood from '../../assets/interwood.jpg';
import yhmc from '../../assets/yhmc.jpg';
import cab from '../../assets/cab.jpg';




function Successstories() {

   const sliderNext = () => {
      document.getElementsByClassName("owl-next")[2].click();
    };
  
    const sliderPrev = () => {
      document.getElementsByClassName("owl-prev")[2].click();
    };

  return (
    <>
    {/* <section className="story-section">
   <div className="container">
      <div className="text-area wow fadeInDown" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
         <h2>Success Story 1</h2>
         <p>In such a global competition era, ERP has become the focus of corporate operations and management. Such ERP systems does not only tackle information technology issues but they are real time business problem solver i.e related to production, sales, purchase, inventory human resource, enterprice culture and organizational restructuring and so on</p>
         <a data-fancybox="" href="https://www.youtube.com/watch?v=7e90gBu4pas" className="btn button btn-rounded trans-btn btn-hvr-white wow fadeInUp" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            Read More
            <div className="btn-hvr-setting">
               <ul className="btn-hvr-setting-inner">
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
               </ul>
            </div>
         </a>
      </div>
   </div>
    </section>
   <br></br><br></br>
   <section className="story-section">
   <div className="container">
      <div className="text-area wow fadeInDown" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
         <h2>Success Story 2</h2>
         <p>The Comptroller and Auditor General of India discharge his multifarious duties through the Indian Audits and Accounts Department.The Department Consists of about fifty thousand employees and is functionally organized into 133 specialized formations throughout the country.</p>
         <a data-fancybox="" href="https://www.youtube.com/watch?v=7e90gBu4pas" className="btn button btn-rounded trans-btn btn-hvr-white wow fadeInUp" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            Read More
            <div className="btn-hvr-setting">
               <ul className="btn-hvr-setting-inner">
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
               </ul>
            </div>
         </a>
      </div>
   </div>
   </section>
   <br></br><br></br>
   <section className="story-section">
   <div className="container">
      <div className="text-area wow fadeInDown" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
         <h2>Success Story 3</h2>
         
            <p>Enabled INTER WOOD Mobel to achieve an exponential business growth.</p>
            <p>Align its sales processes as per industry standards</p>
            <p>Track site work efficiency increase customer satisfaction</p>
            <p>Ensure uniform Pricingacross its sales outlet sand dealer</p>
         <a data-fancybox="" href="https://www.youtube.com/watch?v=7e90gBu4pas" className="btn button btn-rounded trans-btn btn-hvr-white wow fadeInUp" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            Read More
            <div className="btn-hvr-setting">
               <ul className="btn-hvr-setting-inner">
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
                  <li className="btn-hvr-effect"></li>
               </ul>
            </div>
         </a>
      </div>
   </div>
   </section> */}


<section>
   <div>
   <h1
                className=" wow bounceInLeft mobile-font-size corausel-text-2 mb-3"
                data-wow-delay="300ms"
                style={{color:'black', textAlign:'center', paddingBottom:'40px'}}
              >
                Our Success Stories
              </h1>
      <div
        className="position-relative"
        style={{ position: "relative", display: "flex" }}
      >
        <div className="position-absolute h-100 left-0 top-0 d-flex align-items-center px-2  z-2">
          <div
            onClick={sliderPrev}
            className="carousel-nav-btn d-flex justify-content-center align-items-center"
          >
            <BsChevronLeft color="#fff" size={15} />
          </div>
        </div>

        <div className="position-absolute h-100 right-0 top-0 d-flex align-items-center px-2 z-2">
          <div
            onClick={sliderNext}
            className="carousel-nav-btn d-flex justify-content-center align-items-center"
          >
            <BsChevronRight color="#fff" size={15} />
          </div>
        </div>

        <OwlCarousel
          items={1}
          className="owl-theme"
          loop
          nav={false}
          dots={false}
          autoplay={true}
        >
          <div className="success-story-slide item position-relative">
            <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            {/* <h1
                className=" wow bounceInLeft mobile-font-size corausel-text-2 mb-3"
                data-wow-delay="300ms"
                style={{color:'white'}}
              >
                Our Success Stories
              </h1> */}
              <img src={yhmc} style={{height:'120px' ,width:'350px', marginBottom:'30px', borderRadius:'8px'}}></img>
              <p className="success-slider-inner-txt" style={{color:'white'}}>In such a global competition era, ERP has become the focus of corporate operations and management. Such ERP systems does not only tackle information technology issues but they are real time business problem solver i.e related to production, sales, purchase, inventory human resource, enterprice culture and organizational restructuring and so on</p>
              {/* <p
                className=" wow bounceInLeft corausel-text-1 mb-1"
                data-wow-delay="300ms"
              >
                Service Since 1995
              </p> */}
              {/* <h1
                className=" wow bounceInLeft corausel-text-2 mb-3"
                data-wow-delay="300ms"
              >
                Access Infotech Pvt Ltd.
              </h1> */}
              {/* <p
                className=" wow bounceInLeft corausel-text-3 mb-3"
                data-wow-delay="300ms"
              >
                The company provides technological solutions to a wide spectrum
                of customers globally, spanning a range of verticals i.e.
                Banking & Insurance, Manufacturing, Contracting, Retail &
                Distribution, Educational Institutions
              </p> */}

              {/* <a className="corausel-action-btn">
                Learn More
              </a> */}

              {/* <Link class="wow bounceInLeft btn button btn-rounded btn-white btn-hvr-blue corausel-action-btn" to='/about-us'> */}
              <br></br>
              {/* <a
                class="wow bounceInLeft btn button btn-rounded btn-white btn-hvr-blue service-corausel-action-btn"
                href="#" style={{color:'white', borderColor:'white'}}
              >
                Learn More */}
                {/* <div className="btn-hvr-setting">
                  <ul className="btn-hvr-setting-inner">
                    <li className="btn-hvr-effect"></li>
                    <li className="btn-hvr-effect"></li>
                    <li className="btn-hvr-effect"></li>
                    <li className="btn-hvr-effect"></li>
                  </ul>
                </div> */}
              {/* </a> */}
              {/* </Link> */}
            </div>
          </div>


          <div className="success-story-slide item position-relative">
            <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            {/* <h1
                className="mobile-font-size corausel-text-2 mb-3"
                // data-wow-delay="300ms"
                style={{color:'white'}}
              >
                Our Success Stories
              </h1> */}
            <img src={cab} style={{height:'120px' ,width:'350px', marginBottom:'30px',borderRadius:'8px'}}></img>
              <p className="success-slider-inner-txt" style={{color:'white'}}>The Comptroller and Auditor General of India discharge his multifarious duties through the Indian Audits and Accounts Department.The Department Consists of about fifty thousand employees and is functionally organized into 133 specialized formations throughout the country.</p>
              {/* <p className=" corausel-text-1 mb-1" data-wow-delay="300ms">
                Service Since 1995
              </p>
              <h1 className=" corausel-text-2 mb-3" data-wow-delay="300ms">
                Digital Agency
              </h1>
              <p className=" corausel-text-3 mb-3" data-wow-delay="300ms">
                We are a new Digital Agency based in USA. We have over 10 years
                of experience about designing websitesand mobile apps.
              </p> */}

              {/* <a className="corausel-action-btn">
                Learn More
              </a> */}
               <br></br>
              {/* <a
                class=" btn button btn-rounded btn-white btn-hvr-blue service-corausel-action-btn"
                href="#" style={{color:'white', borderColor:'white'}}
              >
                Learn More */}
                {/* <div class="btn-hvr-setting" style="transition: none 0s ease 0s; text-align: inherit; line-height: 22px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 12px;">
                        <ul class="btn-hvr-setting-inner" style="transition: none 0s ease 0s; text-align: inherit; line-height: 19px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 10px;">
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                        </ul>
                     </div> */}
              {/* </a> */}
            </div>
          </div>


          <div className="success-story-slide item position-relative">
            <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            {/* <h1
                className="mobile-font-size corausel-text-2 mb-3"
                // data-wow-delay="300ms"
                style={{color:'white'}}
              >
                Our Success Stories
              </h1> */}
            <img src={interwood} style={{height:'120px' ,width:'350px', marginBottom:'30px',borderRadius:'8px'}}></img>
              <p className="success-slider-inner-txt" style={{color:'white'}}>
              Enabled INTER WOOD Mobel to achieve an exponential business growth.
            Align its sales processes as per industry standards.
            Track site work efficiency increase customer satisfaction.
            Ensure uniform Pricingacross its sales outlet sand dealer.
              </p>
              {/* <p className=" corausel-text-1 mb-1" data-wow-delay="300ms">
                Service Since 1995
              </p>
              <h1 className=" corausel-text-2 mb-3" data-wow-delay="300ms">
                Digital Company
              </h1>
              <p className=" corausel-text-3 mb-3" data-wow-delay="300ms">
                We are a USA based Digital Agency based. We have 10 years of
                experience about designing websitesand mobile apps.
              </p> */}

              {/* <a className="corausel-action-btn">
                Learn More
              </a> */}
               <br></br>
              {/* <a
                class=" btn button btn-rounded btn-white btn-hvr-blue service-corausel-action-btn"
                href="#" style={{color:'white', borderColor:'white'}}
              >
                Learn More */}
                {/* <div class="btn-hvr-setting" style="transition: none 0s ease 0s; text-align: inherit; line-height: 22px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 12px;">
                        <ul class="btn-hvr-setting-inner" style="transition: none 0s ease 0s; text-align: inherit; line-height: 19px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 10px;">
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                        </ul>
                     </div> */}
              {/* </a> */}
            </div>
          </div>


          <div className="success-story-slide item position-relative">
            <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            {/* <h1
                className="mobile-font-size corausel-text-2 mb-3"
                // data-wow-delay="300ms"
                style={{color:'white'}}
              >
                Our Success Stories
              </h1> */}
            <img src={risl} style={{height:'120px' ,width:'450px', marginBottom:'30px',borderRadius:'8px'}}></img>
              <p className="success-slider-inner-txt" style={{color:'white'}}>
              Development and Integration of FMS with Accountant General’s VLC application for Government of Rajasthan

              </p>
              {/* <p className=" corausel-text-1 mb-1" data-wow-delay="300ms">
                Service Since 1995
              </p>
              <h1 className=" corausel-text-2 mb-3" data-wow-delay="300ms">
                Digital Company
              </h1>
              <p className=" corausel-text-3 mb-3" data-wow-delay="300ms">
                We are a USA based Digital Agency based. We have 10 years of
                experience about designing websitesand mobile apps.
              </p> */}

              {/* <a className="corausel-action-btn">
                Learn More
              </a> */}
               <br></br>
               <br></br>
              {/* <a
                class=" btn button btn-rounded btn-white btn-hvr-blue service-corausel-action-btn"
                href="#" style={{color:'white', borderColor:'white'}}
              >
                Learn More */}
                {/* <div class="btn-hvr-setting" style="transition: none 0s ease 0s; text-align: inherit; line-height: 22px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 12px;">
                        <ul class="btn-hvr-setting-inner" style="transition: none 0s ease 0s; text-align: inherit; line-height: 19px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 10px;">
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                        </ul>
                     </div> */}
              {/* </a> */}
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
</section>
</>
  );
}

export default Successstories;