import { useContext, useRef } from "react";
import Contact from "../Contact";
import Footer from "../Footer";
import Learnmore from "../Learnmore";
import Loader from "../Loader";
import Methodology from "../Methodology";
import Navbar from "../Navbar";
import Services from "../Services";
import Slider from "../Slider";
import Startcounter from "../Startcounter";
import Startportfolio from "../Startportfolio";
import Startquote from "../Startquote";
import Successstories from "../Successstories";
import Team from "../Team";
import Verticals from "../Verticals";
import { ObserverContext } from "../../Contexts/ObserverContext";

function Home() {
  const {
    serviceRef,
    homeRef,
    sliderRef,
    learnMoreRef,
    startCounterRef,
    teamRef,
    successStoriesRef,
    startPortfolioRef,
    startQuoteRef,
    verticalsRef,
    methodologyRef,
    contactRef,
  } = useContext(ObserverContext);
  return (
    <>
      <Slider ></Slider>
      <Services ></Services>
      <Learnmore></Learnmore>
      <Startcounter></Startcounter>
      <Successstories></Successstories>
      <Team></Team>
      <Startquote></Startquote>
      <Startportfolio></Startportfolio>
      <Verticals></Verticals>
      {/* <Methodology></Methodology> */}
      {/* <Contact></Contact> */}
    </>
  );
}

export default Home;
