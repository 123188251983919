// import oracle from '../../assets/oracle.png'
// // import wipro from '../../assets/wipro.png'
// import redhat from '../../assets/redhat.png'
// // import hcl from '../../assets/latesthcl.png'
// import tata from '../../assets/tata.png'
import cer3 from '../../assets/cer3.png'
import cer2 from '../../assets/cer2.webp'
import cer1 from '../../assets/cer1.jpg'
import Verticals from '../Verticals';
function CarouselLearnMore({ref}) {
  return (
    <>
    <div className="container my-5 pt-5" ref={ref}>
      <div className="mt-5">
        <h2 className="text-center mb-4 section-header">Our Profile</h2>
      </div>
      <div>
        <p className="text-center-2 text-type-2">
          Access Infotech was founded in 1995 by a team of IT professionals
          having a strong foundation of high standard corporate governance.<br></br><br></br> The
          company provides technological solutions to a wide spectrum of
          customers across India, spanning a range of verticals i.e. Banking &
          Insurance, Manufacturing, Contracting, Retail & Distribution,
          Educational Institutions, State Government as well as government of
          India.<br></br><br></br> It offers off the shelf Enterprise ERP solutions for the,
          Manufacturing, Contracting, and Retail & Distribution industries.
          Additionally, it also provides Custom Software Development, IT
          Consulting, Enterprise Application Integration, and Managed IT
          Service, among others.<br></br><br></br> The Company has achieved CMMI Level 5, ISO
          27001:2013, ISO 9001:2008, ISO/ IEC 20000:2011 certifications for its
          varied Service offerings.
        </p>
      </div>

      <div className="row mt-5 pt-3">
      <div className="">
        <h2 className="text-center mb-4 section-header">Our Never Ending Mission</h2>
      </div>
      <div>
        <p className="text-center-2 text-type-2">
          Be the Global Leader in developing and delivering Software solutions and IT Services that increase the effectiveness and return of information technology initiatives for our customers.To anticipate, recognizes, and responds to needs, opportunities, and concerns by making available Right Technological Solutions, identifying resources and strategies needed to achieve them.
          Respond quickly, Demonstrate Competence and Craftsmanship, and offer well-researched, flexible solutions, both interim and long-term. Powered by our Shared Vision, we’re constantly reaching towards new horizons.
          We hold extreme pride in being a Family of Committed Professionals and see ourselves as helping to pave the way to a promising future for Indian industry, ushering in a brighter tomorrow.

        </p>
      </div>
      </div>

    </div>
      
      <div style={{backgroundColor:'#f6f6f6', width:'100%'}}>
        <div className='container'>
      <div className="mt-5">
        <h2 className="text-center mb-5 pt-5 section-header">Accreditations</h2>
      </div>
       {/* <h2 className="wow fadeInDown" data-wow-delay="300ms" style={{ visibility: "hidden", animationDelay: "300ms", animationName: "none", marginTop:'50px', marginBottom:'50px'}}>Our Business Partners</h2> */}
       <div className="row" style={{rowGap:'50px'}}>
          {/* <!-- Counter-1 --> */}
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 wow fadeInLeft" data-wow-delay="400ms" style={{ visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
             {/* <div className="counter"> */}
               <img src={cer1} style={{height:'60%', width:'80%'}}></img>
             {/* </div> */}
          </div>
          {/* <!-- Counter-2 --> */}
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 wow fadeInLeft" data-wow-delay="400ms" style={{ visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
             {/* <div className="counter"> */}
                <img src={cer2 } style={{height:'60%', width:'80%'}}></img>
             {/* </div> */}
          </div>
          {/* <!-- Counter-3 --> */}
          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 wow fadeInRight" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
             {/* <div className="counter"> */}
                <img src={cer3} style={{height:'60%', width:'80%'}}></img>
                {/* </div> */}
          </div>
       </div>
    {/* </div> */}
    </div>
    </div>
    <Verticals></Verticals>
    </>
  );
}

export default CarouselLearnMore;
