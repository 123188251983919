import { useState, useEffect } from "react";
import { isElement } from "react-dom/test-utils";

function IntersectionObserverHook(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    console.log('Custom hook is called',ref);
    // const observer = new IntersectionObserver(([entry]) => {
    //   console.log('this is our Intersection Observer Response', entry);
    //   setIntersecting(entry.isIntersecting);
    // });
// if(isElement(ref.current)){
//     observer.observe(ref.current);
// }
//     return () => {
//       observer.disconnect();
    // };
  }, [ref]);

  return isIntersecting;
}

export default IntersectionObserverHook;
