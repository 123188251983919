// import { useState } from 'react';
import oracle1 from '../../assets/self.jpg'
import oracle2 from '../../assets/oracleLatest2.png'
// import wipro from '../../assets/wipro.png'
import redhat from '../../assets/redhatLatest.png'
// import hcl from '../../assets/latesthcl.png'
import aws from '../../assets/awsLatest.png'
// import hp from '../../assets/hp.png'
import horton from '../../assets/horton.png'
import railtel from '../../assets/railtelNew1.jpg'
import himachal from '../../assets/himachal.jpg'
import gravience from '../../assets/Punjab-Government-LOGO.jpg'
import nvm from '../../assets/NIUA_Logo_new.png'
import oracle3 from '../../assets/op.png';


import { useContext, useEffect, useRef } from "react";
import { ObserverContext } from "../../Contexts/ObserverContext";

function Verticals() {
   const ref = useRef();

  const { activeSection, setActiveSection } = useContext(ObserverContext);
  
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // set value in context for section = team.
        setActiveSection('alliances');
        console.log('this is updated section',activeSection)
      }
    });
    if (ref && ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);
  return (

   //  <p>This is Pricing/Verticals Page</p>
    <section id="alliances" ref={ref}>
    <div className="container">
       <h2 className="wow fadeInDown" data-wow-delay="300ms" style={{ visibility: "hidden", animationDelay: "300ms", animationName: "none", marginTop:'50px', marginBottom:'50px'}}>Our Business Alliances</h2>
       <div className="row pb-5" style={{rowGap:'50px'}}>
         
       <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 wow fadeInLeft" data-wow-delay="400ms" style={{ visibility: "hidden", animationDelay: "400ms", animationName: "none",height:'150px',textAlign:'end'}}>
               <img src={oracle3} style={{height:'100%'}} ></img>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 wow fadeInLeft" data-wow-delay="400ms" style={{ visibility: "hidden", animationDelay: "400ms", animationName: "none",height:'150px'}}>
               <img src={oracle1} style={{height:'100%', width:'100%'}} ></img>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 wow fadeInLeft" data-wow-delay="400ms" style={{ visibility: "hidden", animationDelay: "400ms", animationName: "none", height:'150px'}}>
                <img src={ oracle2 } style={{height:'100%',width:'100%'}}></img>
          </div>
       </div>
    </div>
    <div class="w-full custom-display gap-4 ">
    <div className=" wow fadeInRight width" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
                <img src={aws} style={{width:'100%',}}></img>
          </div>

          <div className=" wow fadeInRight custom-width" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
                <img src={redhat} style={{ width:'100%'}}></img> 
          </div>

          <div className=" wow fadeInRight custom-width" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
                <img src={horton} style={{width:'100%'}}></img>
          </div>

          <div className=" wow fadeInRight custom-width" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
                <img src={railtel} style={{ width:'100%'}}></img>
          </div>

          <div className=" wow fadeInRight custom-width" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
                <img src={himachal} style={{ width:'100%'}}></img>
          </div>

          <div className=" wow fadeInRight custom-width" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none"}}>
                <img src={gravience} style={{ width:'100%'}}></img>
          </div>
          <div className=" wow fadeInRight custom-width" data-wow-delay="400ms" style={{visibility: "hidden", animationDelay: "400ms", animationName: "none", justifyContent:'center'}}>
                <img src={nvm} style={{ width:'100%'}}></img>
          </div>
    </div>
 </section>
  );
}

export default Verticals;

