// import { useState } from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { useContext, useEffect, useRef } from "react";
import { ObserverContext } from "../../Contexts/ObserverContext";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";

function Learnmore() {

   const sliderNext = () => {
      document.getElementsByClassName("owl-next")[1].click();
    };
  
    const sliderPrev = () => {
      document.getElementsByClassName("owl-prev")[1].click();
    };

  return (
    <section>
   <div>
      <div
        className="position-relative"
        style={{ position: "relative", display: "flex" }}
      >
        <div className="position-absolute h-100 left-0 top-0 d-flex align-items-center px-2  z-2">
          <div
            onClick={sliderPrev}
            className="carousel-nav-btn d-flex justify-content-center align-items-center"
          >
            <BsChevronLeft color="#fff" size={15} />
          </div>
        </div>

        <div className="position-absolute h-100 right-0 top-0 d-flex align-items-center px-2 z-2">
          <div
            onClick={sliderNext}
            className="carousel-nav-btn d-flex justify-content-center align-items-center"
          >
            <BsChevronRight color="#fff" size={15} />
          </div>
        </div>

        <OwlCarousel
          items={1}
          className="owl-theme"
          loop
          nav={false}
          dots={false}
          autoplay={true}
        >
          <div className="service-slide item position-relative">
            <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
              <h1 style={{ fontWeight:'600', color:'white', textAlign:"center"}} className="mobile-font-size"> Enterprice Resource Planning</h1>
              {/* <p
                className=" wow bounceInLeft corausel-text-1 mb-1"
                data-wow-delay="300ms"
              >
                Service Since 1995
              </p>
              <h1
                className=" wow bounceInLeft corausel-text-2 mb-3"
                data-wow-delay="300ms"
              >
                Access Infotech Pvt Ltd.
              </h1>
              <p
                className=" wow bounceInLeft corausel-text-3 mb-3"
                data-wow-delay="300ms"
              >
                The company provides technological solutions to a wide spectrum
                of customers globally, spanning a range of verticals i.e.
                Banking & Insurance, Manufacturing, Contracting, Retail &
                Distribution, Educational Institutions
              </p> */}

              {/* <a className="corausel-action-btn">
                Learn More
              </a> */}

              {/* <Link class="wow bounceInLeft btn button btn-rounded btn-white btn-hvr-blue corausel-action-btn" to='/about-us'> */}
              <br></br>
              <br></br>
              <a
                class="wow bounceInLeft btn button btn-rounded btn-white btn-hvr-blue service-corausel-action-btn"
                href="/erp-services" style={{ color:'white', borderColor: 'white'}}
              >
                Learn More
                {/* <div className="btn-hvr-setting">
                  <ul className="btn-hvr-setting-inner">
                    <li className="btn-hvr-effect"></li>
                    <li className="btn-hvr-effect"></li>
                    <li className="btn-hvr-effect"></li>
                    <li className="btn-hvr-effect"></li>
                  </ul>
                </div> */}
              </a>
              {/* </Link> */}
            </div>
          </div>


          <div className="service-slide item position-relative">
            <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            <h1 style={{ fontWeight:'600', color:'white', textAlign:"center"}} className="mobile-font-size">Database Adminstration Services</h1>
              {/* <p className=" corausel-text-1 mb-1" data-wow-delay="300ms">
                Service Since 1995
              </p>
              <h1 className=" corausel-text-2 mb-3" data-wow-delay="300ms">
                Digital Agency
              </h1>
              <p className=" corausel-text-3 mb-3" data-wow-delay="300ms">
                We are a new Digital Agency based in USA. We have over 10 years
                of experience about designing websitesand mobile apps.
              </p> */}

              {/* <a className="corausel-action-btn">
                Learn More
              </a> */}
               <br></br>
               <br></br>
              <a
                class=" btn button btn-rounded btn-white btn-hvr-blue service-corausel-action-btn"
                href="/database-services" style={{ color:'white', borderColor: 'white'}}
              >
                Learn More
                {/* <div class="btn-hvr-setting" style="transition: none 0s ease 0s; text-align: inherit; line-height: 22px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 12px;">
                        <ul class="btn-hvr-setting-inner" style="transition: none 0s ease 0s; text-align: inherit; line-height: 19px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 10px;">
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                        </ul>
                     </div> */}
              </a>
            </div>
          </div>


          <div className="service-slide item position-relative">
            <div className="container h-100 d-flex flex-column justify-content-center align-items-center">
            <h1 style={{ fontWeight:'600', color:'white', textAlign:"center"}} className="mobile-font-size">Customised Application Development</h1>
              {/* <p className=" corausel-text-1 mb-1" data-wow-delay="300ms">
                Service Since 1995
              </p>
              <h1 className=" corausel-text-2 mb-3" data-wow-delay="300ms">
                Digital Company
              </h1>
              <p className=" corausel-text-3 mb-3" data-wow-delay="300ms">
                We are a USA based Digital Agency based. We have 10 years of
                experience about designing websitesand mobile apps.
              </p> */}

              {/* <a className="corausel-action-btn">
                Learn More
              </a> */}
               <br></br>
               <br></br>
              <a
                class=" btn button btn-rounded btn-white btn-hvr-blue service-corausel-action-btn"
                href="/custom-development" style={{ color:'white', borderColor: 'white'}}
              >
                Learn More
                {/* <div class="btn-hvr-setting" style="transition: none 0s ease 0s; text-align: inherit; line-height: 22px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 12px;">
                        <ul class="btn-hvr-setting-inner" style="transition: none 0s ease 0s; text-align: inherit; line-height: 19px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 10px;">
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                           <li class="btn-hvr-effect" style="transition: none 0s ease 0s; text-align: inherit; line-height: 16px; border-width: 0px; margin: 0px; padding: 0px; letter-spacing: 0px; font-weight: 500; font-size: 9px;"></li>
                        </ul>
                     </div> */}
              </a>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
</section>
  );
}

export default Learnmore;