import { faEnvelope, faHeadphonesSimple, faMapMarkerAlt, faPhone, faQuestion, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef } from 'react';
import { ObserverContext } from '../../Contexts/ObserverContext';

function Contact() {
   const ref = useRef();

  const { activeSection, setActiveSection } = useContext(ObserverContext);
  
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // set value in context for section = team.
        setActiveSection('contact');
        console.log('this is updated section',activeSection)
      }
    });
    if (ref && ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return (
    <section className="contact-section" id="contact" ref={ref}>
   <h2 className="wow fadeInDown" data-wow-delay="300ms" style={{ visibility: "hidden", animationDelay: "300ms", animationName: "none", marginTop:'50px', marginBottom:'25px', textAlign:'center'}}>Contact Us</h2>
   <div className="container">
      <div className="row flex-lg-nowrap row-2">
         {/* <div className="col-lg-8 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}> */}
            {/* <h2>Let's Get In Touch</h2>
            <form className="contact-form" id="contact-form-data">
               <div className="row">
                  <div className="col-12" id="result"></div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                     <div className="form-group">
                        <label>Name</label>
                        <input className="form-control" type="email" placeholder="Your Name" required="" name="userName"/>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                     <div className="form-group">
                        <label>Email</label>
                        <input className="form-control" type="email" placeholder="email@website.com" required="" name="userEmail"/>
                     </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                     <div className="form-group">
                        <label>Message</label>
                        <textarea className="form-control" placeholder="Write your message here" name="userMessage" rows="6" cols="50"></textarea>
                     </div>
                  </div>
                  <a href="JavaScript:void(0);" className="btn button btn-rounded btn-light-blue btn-hvr-light-blue contact_btn">SEND
                     <div className="btn-hvr-setting">
                        <ul className="btn-hvr-setting-inner">
                           <li className="btn-hvr-effect"></li>
                           <li className="btn-hvr-effect"></li>
                           <li className="btn-hvr-effect"></li>
                           <li className="btn-hvr-effect"></li>
                        </ul>
                     </div>
                  </a>
               </div>
            </form> */}
         {/* </div> */}
         {/* <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInRight" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}> */}
            {/* <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis accumsan.
            </p> */}
            <div className="media col-lg-3 col-md-12 col-sm-12 flex-column align-items-center" style={{width:'95%'}}>
            <FontAwesomeIcon icon={faLocationDot} size="2xl" />
               <div className="media-body mt-4 ml-0">
                  <h5 style={{textAlign:'center', textDecoration:'underline'}}>Address</h5>
                  <p style={{textAlign:'center'}}>Plot No. B-31, Industrial Area, Phase-3, Mohali-160055<br></br>  9988901721 , 9988901739</p>
                  {/* <p>9988901721 , 9988901739</p> */}
               </div>
            </div>
            <div className="media col-lg-3 col-md-12 col-sm-12 flex-column align-items-center">
               <FontAwesomeIcon icon={faHeadphonesSimple} size="2xl" />
               <div className="media-body mt-4 ml-0">
                  <h5 style={{textAlign:'center', textDecoration:'underline'}}>SALES</h5>
                  {/* <p>For Sales Enquiry Contact Us At:</p> */}
                  <p>sales@accessinfotechindia.com</p>
               </div>
            </div>
            <div className="media col-lg-3 col-md-12 col-sm-12 flex-column align-items-center">
               <FontAwesomeIcon icon={faQuestion} size="2xl" />
               <div className="media-body mt-4 ml-0">
                  <h5 style={{textAlign:'center', textDecoration:'underline'}}>INFORMATION AND QUERIES</h5>
                  {/* <p>For general information contact us at:</p> */}
                  <p>info@accessinfotechindia.com</p>
               </div>
            </div>
            <div className="media col-lg-3 col-md-12 col-sm-12 flex-column align-items-center">
               <FontAwesomeIcon icon={faEnvelope} size="2xl" />
               <div className="media-body mt-4 ml-0">
                  <h5 style={{textAlign:'center', textDecoration:'underline'}}>CAREERS</h5>
                  {/* <p>For careers options contact HR at:</p> */}
                  <p>careers@accessinfotechindia.com</p>
               </div>
            </div>
         </div>
      </div>
   {/* </div> */}
</section>
    
  );
}

export default Contact;