// import { useState } from 'react';

import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef } from "react";
import { ObserverContext } from "../../Contexts/ObserverContext";

function Startquote() {
   const ref = useRef();

  const { activeSection, setActiveSection } = useContext(ObserverContext);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // set value in context for section = team.
        setActiveSection('projects');
        console.log('this is updated section',activeSection)
      }
    });
    if (ref && ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);
  return (
    // <p>This is Startquote Page</p>
    <section className="quote-section" id='projects' ref={ref}>
   <div className="container">
      <div className="text-section wow zoomIn" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
         <div className="left-quote">
            <FontAwesomeIcon icon={faQuoteLeft} size="2xl" />
         </div>
         <h3>Recent Projects</h3>
         <i><p>Intelligent and Diligent team provide smart solutions</p></i>
         <div className="right-quote">
         <FontAwesomeIcon icon={faQuoteRight} size="2xl" />
         </div>
      </div>
   </div>
</section>
  );
}

export default Startquote;