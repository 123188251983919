// import { useEffect } from 'react';
// import WOW from 'wowjs';

function Loader() {
  // useEffect(() => {
  //   const wow = new WOW.WOW();
  //   wow.init();
  // });

  return (
    <div className="pre-loader" style={{display: 'none'}} className="wow bounceIn">
      <div className="loader" style={{display: 'none'}}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Loader;
