// import { useState } from 'react';

import { faCloud, faCode, faDatabase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ser1 from '../../assets/service1.png';
import ser2 from '../../assets/service2.png';
import ser3 from '../../assets/service3.png';
import { useContext, useEffect, useRef } from "react";
import { BsPersonFillCheck,BsLayoutWtf } from 'react-icons/bs';
import { FaLaptop } from 'react-icons/fa';
import { ObserverContext } from "../../Contexts/ObserverContext";

function Services(){

   const ref = useRef();

  const { activeSection, setActiveSection } = useContext(ObserverContext);

  useEffect(() => {
   const observer = new IntersectionObserver(([entry]) => {
     if (entry.isIntersecting) {
       // set value in context for section = team.
       setActiveSection('services');
       console.log('this is updated section',activeSection)
     }
   });
   if (ref && ref.current) {
     observer.observe(ref.current);
   }
   return () => {
     observer.disconnect();
   };
 }, [ref]);

  return (
    // <p>This is Services Page</p>
    <section className="services-section" id="services" ref={ref}>
   <div className="container">
      <h2 className="wow fadeInDown" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>Our Exclusive Services</h2>
      <p className="wow fadeInDown" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>We offer off the shelf Enterprise ERP solutions for the, Manufacturing, Contracting, and Retail.</p>
      <div className="row no-gutters ">
         {/* <!-- Box-1 --> */}
         <div className="col-lg-4 col-md-4 col-sm-12 flip-boxes wow fadeInLeft" data-wow-delay="400ms" style={{visibility: 'hidden', animationDelay: '400ms', animationName: 'none'}}>
            <div className="flip-box">
               <div className="flip-box-inner">
                  <div className="flip-box-front">
                  <FontAwesomeIcon icon={faCloud} size="2xl" style={{marginTop:'40px', marginBottom:'5px'}}/>
                  {/* <img src={ser1} style={{marginTop:'40px', marginBottom:'40px'}}/> */}
                     <h5>CLOUD BASED ERP SERVICES</h5>
                     <p style={{fontWeight:'400'}}>Provides professional Cloud Services and implementation support for the cloud.</p>
                  </div>
                  <div className="flip-box-back">
                  <FontAwesomeIcon icon={faCloud} size="2xl" style={{marginTop:'40px', marginBottom:'5px', color:'#FFFFFF'}}/>
                  {/* <img src={ser1} style={{marginTop:'40px', marginBottom:'40px'}}/> */}
                     <h5>CLOUD BASED ERP SERVICES</h5>
                     <p>Provides professional Cloud Services and implementation support for the cloud.</p>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- Box-2 --> */}
         <div className="col-lg-4 col-md-4 col-sm-12 flip-boxes wow fadeIn" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>
            <div className="flip-box">
               <div className="flip-box-inner blue-box">
                  <div className="flip-box-front">
                  <FontAwesomeIcon icon={faDatabase} size="2xl" style={{marginTop:'40px',marginBottom:'5px', color:'#032a58'}} />
                  {/* <img src={ser2} style={{marginTop:'40px', marginBottom:'40px'}}/> */}
                     <h5>DATABASE ADMINSTRATION SERVICES</h5>
                     <p style={{fontWeight:'400'}}>Database Architecture</p>
                     <p style={{fontWeight:'400'}}>Database Adminstration and Support</p>
                     <p style={{fontWeight:'400'}}>Database Maintenance</p>
                     <p style={{fontWeight:'400'}}>Data Mining and Data Warehousing</p>
                  </div>
                  <div className="flip-box-back">
                  <FontAwesomeIcon icon={faDatabase} size="2xl" style={{marginTop:'40px',marginBottom:'5px', color:'#FFFFFF'}} />
                  {/* <img src={ser2} style={{marginTop:'40px', marginBottom:'40px'}}/> */}
                     <h5>DATABASE ADMINSTRATION SERVICES</h5>
                     <p>Database Architecture</p>
                     <p>Database Adminstration and Support</p>
                     <p>Database Maintenance</p>
                     <p>Data Mining and Data Warehousing</p>
                  </div>
               </div>
            </div>
         </div>
         {/* <!-- Box-3 --> */}
         <div className="col-lg-4 col-md-4 col-sm-12 flip-boxes wow fadeInRight" data-wow-delay="400ms" style={{visibility: 'hidden', animationDelay: '400ms', animationName: 'none'}}>
            <div className="flip-box">
               <div className="flip-box-inner">
                  <div className="flip-box-front">
                     <FaLaptop style={{marginTop:'40px',marginBottom:'5px',  fontSize:'35px'}}></FaLaptop>
                  {/* <FontAwesomeIcon icon={faCode} size="2xl" style={{marginTop:'40px',marginBottom:'40px'}}/> */}
                  {/* <img src={ser3} style={{marginTop:'40px', marginBottom:'40px'}}/> */}
                     <h5>CUSTOMIZED APPLICATION DEVELOPMENT</h5>
                     <p style={{fontWeight:'400'}}>We develop customized application softwares for various industries verticals such as Textile, Footwear, Chemical, logistics.</p>
                  </div>
                  <div className="flip-box-back">
                  <FaLaptop style={{marginTop:'40px',marginBottom:'5px',  fontSize:'35px', color:'white'}}></FaLaptop>
                  {/* <FontAwesomeIcon icon={faCode} size="2xl" style={{marginTop:'40px',marginBottom:'40px', color:'#FFFFFF'}}/> */}
                  {/* <img src={ser3} style={{marginTop:'40px', marginBottom:'40px'}}/> */}
                     <h5>CUSTOMIZED APPLICATION DEVELOPMENT</h5>
                     <p>We develop customized application softwares for various industries verticals such as Textile, Footwear, Chemical, logistics.</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
  );
}

export default Services;