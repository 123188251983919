// import { useState } from 'react';
import { FaFacebook, FaGooglePlusG, FaInstagram, FaLinkedin, FaRegEnvelope, FaTwitter } from "react-icons/fa";
function Footer({ref}) {
  return (
    <footer class="no-gutters">
   {/* <div class="container">
       <div class="footer-social-icons wow fadeInDown" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}> */}
         {/* <ul>
            <li><a class="facebook-hovr" href="javascript:void(0);"><FaFacebook></FaFacebook></a></li>
            <li><a href="javascript:void(0);"><FaTwitter></FaTwitter></a></li>
            <li><a href="javascript:void(0);"><FaGooglePlusG></FaGooglePlusG></a></li>
            <li><a href="javascript:void(0);"><FaLinkedin></FaLinkedin></a></li>
            <li><a href="javascript:void(0);"><FaInstagram></FaInstagram></a></li>
            <li><a href="javascript:void(0);"><FaRegEnvelope></FaRegEnvelope></a></li>
         </ul> */}
      {/* </div>  */}
      {/* <p class="wow fadeInDown" data-wow-delay="300ms" style={{visibility: 'hidden', animationDelay: '300ms', animationName: 'none'}}>© 2023 Access Infotech Solutions. made with love by Dev Inventiv Solutions</p> */}
   {/* </div> */}
</footer>
  );
}

export default Footer;