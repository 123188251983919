import airVetrans from "../../assets/vetran.avif";
import ims from "../../assets/jk latest.jpg";
import erp from "../../assets/phonein hand.avif";
import erpPharma from "../../assets/erpPharmaPic.png";

export const jsonData = {
  airVetrans: {
    description: "",
    title: "Computerisation of Pension for Air Veterans, Project",
    subTitle: "Indian Air Force - Directorate of Air Veterans",
    image: airVetrans,
    sections: [
      {
        title: "Key Features",
        keys: [
          "Design and Development of a centralized platform for pensioners and related activities.",
          "Automation of pension process for retired and retiring personnel.",
          "Mechanism to develop integration with other directorates/units/departments and applications.",
          "Managing Data Quality and Single Master Data.",
          "Providing management information system (MIS) for DAV users to get status information and operational reporting needs.",
          "System generated information sharing with pensioners with latest communication modes like email, SMS etc.",
          "Homogeneous system implementation at source (existing)",
        ],
      },
    ],
  },

  ims: {
    description: "",
    title: "Inventory Management System (IMS)",
    subTitle: "Police Headquarters Jammu & Kashmir, Jammu",
    image: ims,
    sections: [
      {
        title: "Key Features",
        keys: [
          "Streamline workflow & processes ",
          "Standard  skills  required",
          "Improved insights for Authorities",
          "Accurate inventory planning",
          "Quick & accurate data ",
          "Better  coordination across Locations",
          "Unified system of reporting",
          "Improved  decision making",
        ],
      },
    ],
  },

  erp: {
    description: "",
    title: "ERP Solution on Mobile",
    subTitle: "Access Infotech Pvt. Ltd",
    image: erp,
    sections: [
      {
        title: "Key Features",
        keys: [
          	'Real time remote success.',
          	'Workflow Management',
          	'Reporting & Analytics',
          	'Security & Access Control',
          	'Tracking & Visibility',
          	'Improving decision-making speed and accuracy',
          	'Better Control'
          
        ],
      },
    ],
  },

  erpPharma: {
    description: "",
    title: "ERP Solution for Pharmaceutical Company",
    subTitle: "Nectar Lifesciences Pvt. Ltd",
    image: erpPharma,
    sections: [
      {
        title: "Key Features",
        keys: [
          'Multi stage tests of raw materials',
          'Lot expiration, stock aging and re-testing requirements',
          'Leads, prospects and customers',
          'Delivery orders, sales contracts and export invoices',
          'Trace inventory including lots, batches and serial numbers.',
          'Generate bar code labels for packaging and shipping',
          'Provide insights on intercompany purchases and sales',
          'Track both costing for planned vs. actual'
          
        ],
      },
    ],
  },
};
