// import { useState } from 'react';
import { useState, useEffect, useRef, useContext } from "react";
import { isElement } from "react-dom/test-utils";

import team1 from "../../assets/NT1.png";
import team2 from "../../assets/NT2.png";
import team3 from "../../assets/NT3.png";
import team4 from "../../assets/NT4.png";
import team5 from "../../assets/NT5.png";

import { ObserverContext } from "../../Contexts/ObserverContext";

function Team() {
  const ref = useRef();

  const { activeSection, setActiveSection } = useContext(ObserverContext);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // set value in context for section = team.
        setActiveSection("team");
        console.log("this is updated section", activeSection);
      }
    });
    if (ref && ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref]);
  return (

    <section class="team-section " id="team">
      <div class="">
        <h2
          class="wow fadeInDown"
          data-wow-delay="300ms"
          style={{
            visibility: "hidden",
            animationDelay: "300ms",
            animationName: "none",
          }}
        >
          Meet The Team
        </h2>
        <p
          class="wow fadeInDown"
          data-wow-delay="300ms"
          style={{
            visibility: "hidden",
            animationDelay: "300ms",
            animationName: "none",
          }}
        >
          With over 27 years of combined experience, we've got a well-seasoned
          team at the helm.
        </p>
        {/* <div
          class="text-center team-section-carousel owl-carousel owl-themes active wow zoomIn owl-loaded owl-drag"
          data-wow-delay="400ms"
          style={{
            visibility: "hidden",
            animationDelay: "400ms",
            animationName: "none",
          }}
        > */}
          {/* <!-- Team-1 --> */}

          {/* <!-- Team-2 --> */}

          {/* <!-- Team-3 --> */}

          {/* <!-- Team-4 --> */}

          {/* <!-- Team-5 --> */}

          <div class="owl-carousel">
          <div>
              <div
                className=""
                style={{ width: "200px", marginRight: "15px" }}
              >
                <div className="item">
                  <div style={{height:'250px', overflow:'hidden'}}>
                  <img src={team1} alt="image" />
                  </div>
                  <div className="team-detail">
                    <h5 style={{fontSize: '18px'}}>Jashinder Bir Singh</h5>
                    <p style={{fontSize: '15px'}}>Founder</p>
                  </div>
                </div>
            </div>
            </div>
            
            <div>
              <div
                className=""
                style={{ width: "200px", marginRight: "15px" }}
              >
                <div className="item">
                  <div style={{height:'250px', overflow:'hidden'}}>
                  <img src={team2} alt="image" />
                  </div>
                  <div className="team-detail">
                    <h5 style={{fontSize: '18px'}}>Chandandeep Kaur</h5>
                    <p style={{fontSize: '15px'}}>Director Co Founder</p>
                  </div>
                </div>
            </div>
            </div>

            <div>
              <div
                className=""
                style={{ width: "200px", marginRight: "15px" }}
              >
                <div className="item">
                  <div style={{height:'250px', overflow:'hidden'}}>
                  <img src={team4} alt="image" />
                  </div>
                  <div className="team-detail">
                    <h5 style={{fontSize: '18px'}}>Onkar Dhar</h5>
                    <p style={{fontSize: '15px'}}>Director Sales</p>
                  </div>
                </div>
            </div>
            </div>

            <div>
              <div
                className=""
                style={{ width: "200px", marginRight: "15px" }}
              >
                <div className="item">
                  <div style={{height:'250px', overflow:'hidden'}}>
                  <img src={team5} alt="image" />
                  </div>
                  <div className="team-detail">
                    <h5 style={{fontSize: '18px'}}>Rajan Vasudev</h5>
                    <p style={{fontSize: '15px'}}>Director Finance</p>
                  </div>
                </div>
            </div>
            </div>

            <div>
              <div
                className=""
                style={{ width: "200px", marginRight: "15px" }}
              >
                <div className="item">
                  <div style={{height:'250px', overflow:'hidden'}}>
                  <img src={team3} alt="image" />
                  </div>
                  <div className="team-detail">
                    <h5 style={{fontSize: '18px'}}>Ashwani Prabhakar</h5>
                    <p style={{fontSize: '15px'}}>General Manager Delivery</p>
                  </div>
                </div>
            </div>
            </div>


            
            
          </div>
          <div class="owl-dots">
            {/* <button role="button" class="owl-dot">
              <span></span>
            </button> */}
            {/* <button role="button" class="owl-dot">
              <span></span>
            </button> */}
            {/* <button role="button" class="owl-dot active">
              <span></span>
            </button> */}
          </div>
        </div>
      {/* </div> */}
    </section>
  );
}

export default Team;
